import axios from "axios";

// Set a default API_BASE_URL in case no match is found

// let API_BASE_URL = "http://localhost:443";
let API_BASE_URL = "https://api-ls1.core-access.com:3000";
// let API_BASE_URL = "https://scu103.sikorskyfcu.org";

// Create the Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { token: "cCW7PW2CRotxuALrBuMob5lXgVhY4xo" },
});

export default axiosInstance;