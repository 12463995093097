<script setup>
import Header from "./Header.vue"
import HistoryComponentBox from "./HistoryComponentBox.vue"
import api from "../api/api.js"
// import axios from "axios"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import ButtonCancel from "./ButtonCancel.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import moment from 'moment';
import 'moment-timezone'; // Importing moment-timezone
</script>

<template>
  <div class="body-container">
    <header>
      <h1>Member App History</h1>
      <h2>H01</h2>
    </header>

    <div v-if="isLoadingHistory">
      <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
    </div>

    <div class="row" v-if="!isLoadingHistory">
      <table id="table" class="member-history-table">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.name">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="call in callsArr"
            :key="call.MemberNumber"
            :class="{
              notes_row: call.IsHeader === 1,
              header_row: call.IsHeader === 1,
              non_header_row: call.IsHeader !== 1,
            }"
            :style="{
              'background-color': call.IsHeader === 1 ? '#ffffff' : '#d9d9d9',
            }">
            <td
              style="border: 1px solid black; background-color: #ffffff"
              v-if="call.IsHeader === 1">
              {{ call.CallerID }}
            </td>
            <td ref="tdArrowImg" style="border: 1px solid black; padding: 0" v-else>
              <img :src="arrowImage" alt="" />
              <!-- <div class="img-container">
              </div> -->
            </td>
            <td style="border: 1px solid black">{{ call.MemberNumber }}</td>
            <td style="border: 1px solid black">{{ call.MemberName }}</td>
            <td style="border: 1px solid black">{{ readDate(call.CallStartDateTime) }}</td>
            <td
              class="text-center"
              style="border: 1px solid black"
              :style="{
                'background-color': call.IsHeader === 1 ? '#ffffff' : '',
                color: call.IsHeader === 1 ? 'black' : '',
              }">
              <div v-if="call.IsHeader === 1 && call.withNote === 1">
                <ButtonSubmit label="View Notes" @click="openModal(call.InteractionId)" />
              </div>
            </td>
            <td style="border: 1px solid black">{{ call.Authentication }}</td>
            <td style="border: 1px solid black">{{ call.FailureReason }}</td>
            <td style="border: 1px solid black">{{ call.AgentName }}</td>
            <td style="border: 1px solid black">{{ call.InteractionId }}</td>
          </tr>
        </tbody>
      </table>
        <dialog :open="modalOpen">
          <div class="dialog-backdrop"></div>
          <div class="dialog-body">
            <form method="dialog">
              <button type="button" class="close" @click="modalOpen = false">
                <span>&times;</span>
              </button>
              <div v-if="isLoadingNotes">
                <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
              </div>
              <h4 class="dialog-header">Notes for {{ interactionId }}</h4>
              <table id="table" class="table table-sm table-bordered table-hover border-dark">
                <thead>
                  <tr class="notes_row table-primary table-bordered">
                    <th style="border: 1px solid black" class="table-secondary">Category</th>
                    <th style="border: 1px solid black" class="table-secondary">Notes</th>
                    <th style="border: 1px solid black" class="table-secondary">Agent</th>
                    <th style="border: 1px solid black" class="table-secondary">Date Created</th>
                    <th style="border: 1px solid black" class="table-secondary">Date Modified</th>
                  </tr>
                </thead>
                <tbody v-if="!isLoadingNotes">
                  <tr v-for="noteRow in notes.result" :key="noteRow.id" class="notes_row">
                    <td style="border: 1px solid black; text-align: left">
                      {{ noteRow.NoteCategory }}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: left"
                      v-html="noteFormattedText(noteRow.NoteText)"></td>
                    <td style="border: 1px solid black; text-align: left">
                      {{ noteRow.AgentID }}
                    </td>
                    <td style="border: 1px solid black; text-align: left">
                      {{ readDate(noteRow.CreatedAt) }}
                    </td>
                    <td style="border: 1px solid black; text-align: left">
                      {{ readDate(noteRow.ModDateTime) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="button-group" style="margin-top: 20px">
                <ButtonSubmit label="Close" @click="modalOpen = false" />
              </div>
            </form>
          </div>
        </dialog>
        
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryComponent",
  component: [Header, HistoryComponentBox, ButtonCancel, ButtonSubmit],
  data: () => {
    return {
      arrowImage: "",
      setColor: "#007bff",
      setSize: "50px",
      isLoadingHistory: true,
      isLoadingNotes: true,
      hasNotesData: [],
      modalOpen: false,
      interactionId: "example-interaction-id",
      notes: {
        status: "",
        num_rows: "",
        result: {},
      },
      company: localStorage.getItem('companyID'),
      authToken: localStorage.getItem("authToken"),
      callsArr: [],
      columns: [
        { name: "CallerID", label: "Caller ID" },
        { name: "MemberNumber", label: "Member #" },
        { name: "MemberName", label: "Caller Name" },
        { name: "CreatedAt", label: "Date" },
        { name: "", label: "Note" },
        { name: "AuthStatus", label: "Status" },
        { name: "FailureReason", label: "Failure Reason" },
        { name: "AgentName", label: "Agent" },
        { name: "InteractionId", label: "Interaction ID" },
        
      ],
      localStorageLoginUp: localStorage.getItem("passLoginUp"),
      parsedLoginUp: "",
    }
  },
  computed: {
    /*
        sortedCalls() {
            const key = this.sortColumn
            const order = this.sortDesc ? -1 : 1

            return this.callsArr.slice().sort((a, b) => {
                const valA = this.getValueByKey(a, key)
                const valB = this.getValueByKey(b, key)

                if (valA < valB) return -order
                if (valA > valB) return order
                return 0
            })
        },*/
    noteFormattedText() {
      return function (text) {
        return text.replace(/\\n/g, "<br>")
      }
    },
  },
  props: {
    userData: Object,
    brandData: Object,
  },
  updated() {
    // if (this.$refs.tdArrowImg) {
    //   Object.values(this.$refs.tdArrowImg).forEach((el) => {
    //     console.log(el.clientHeight)
    //     if (el.clientHeight >= 59) {
    //       // this.arrowImage = require("@/assets/arrow2.png")
    //     } else {
    //       // el.style.width = "140px"
    //       // this.arrowImage = require("@/assets/arrow1.png")
    //     }
    //   })
    // }
    if (this.$refs.tdArrowImg) {
      Object.values(this.$refs.tdArrowImg).forEach((el) => {
        if (el.clientHeight >= 59 && el.clientHeight < 80) {
          this.arrowImage = require("@/assets/arrowmid.png")
        } else if (el.clientHeight >= 80) {
          this.arrowImage = require("@/assets/arrowhigh.png")
        } else {
          this.arrowImage = require("@/assets/arrowlow.png")
        }
      })
    }
  },
  async mounted() {
    if(this.localStorageLoginUp){
      this.parsedLoginUp = JSON.parse(this.localStorageLoginUp);
    }
    window.addEventListener(
      "resize",
      () => {
        Object.values(this.$refs.tdArrowImg).forEach((el) => {
          if (el.clientHeight >= 59 && el.clientHeight < 80) {
            this.arrowImage = require("@/assets/arrowmid.png")
          } else if (el.clientHeight >= 80) {
            this.arrowImage = require("@/assets/arrowhigh.png")
          } else {
            this.arrowImage = require("@/assets/arrowlow.png")
          }
        })
      },
      true
    )
    // window.addEventListener(
    //   "resize",
    //   () => {
    //     Object.values(this.$refs.tdArrowImg).forEach((el) => {
    //       if (el.clientHeight >= 59 && el.clientHeight < 80) {
    //         console.log(el.clientHeight)
    //         this.arrowImage = require("@/assets/arrow2.png")
    //       } else if (el.clientHeight >= 80) {
    //         // el.style.width = "140px"
    //         console.log("2")
    //         // this.arrowImage = require("@/assets/arrow2.png")
    //       } else {
    //         this.arrowImage = require("@/assets/arrow1.png")
    //       }
    //     })
    //   },
    //   true
    // )
    this.setColor = this.brandData.accent_color1
    //  console.log(this.brandData)
    //  console.log('historyauth',this.authToken)
    try {
      const callerInfo = await api.post("getCallerInfoById", {
        company: this.company,
        indexNum: this.authToken,
      })

      const resAuthCalls = await api.post("getAuthCallsById", {
        company: this.company,
        callerid: callerInfo.data.CallerID,
      })
      //  console.log('company',this.company)
      //  console.log('callerid',callerInfo.data.CallerID)
      this.authCallsArr = resAuthCalls.data
      //  console.log('this.authCallsArr',   this.authCallsArr)

      this.callsArr = resAuthCalls.data
      this.isLoadingHistory = false
    } catch (error) {
      //  console.log(error)
    }
  },
  unmounted() {
    window.removeEventListener("resize", () => {}, true)
  },
  methods: {
    readDate(date) {
      // const dateObj = new Date(data);
      // const formattedDateString = dateObj.toLocaleString();
      // return moment(formattedDateString).format("YYYY-MM-DD HH:mm:ss")
      return moment(date).tz(this.parsedLoginUp.time_zone).format("YYYY-MM-DD HH:mm:ss");
    },
    openModal(interactionId) {
      this.isLoadingNotes = true
      this.modalOpen = true
      this.interactionId = interactionId
      this.GetFraudNotesByInteraction(this.company, interactionId)

      //  console.log('THIS NOTES',this.notes)
      //  console.log('THIS interactionid',interactionId)
      // this.$nextTick(() => {
      //   const modal = document.getElementById("notes-modal-" + interactionId)
      //   if (modal) {
      //     modal.classList.add("show")
      //     modal.style.display = "block"
      //     document.body.classList.add("modal-open")
      //     modal.style.zIndex = "9999" // Increase z-index

      //     // Check if backdrop already exists, create one if it doesn't
      //     let backdrop = document.querySelector(".modal-backdrop")
      //     if (!backdrop) {
      //       backdrop = document.createElement("div")
      //       backdrop.classList.add("modal-backdrop")
      //       document.body.appendChild(backdrop)
      //     }

      //     // Add event listener to close the modal when clicking outside of it
      //     backdrop.addEventListener("click", this.closeModal)
      //     backdrop.style.pointerEvents = "auto" // Enable pointer events on backdrop
      //   }
      // })
    },

    closeModal() {
      const modal = document.getElementById("notes-modal-" + this.interactionId)
      if (modal) {
        modal.classList.remove("show")
        modal.style.display = "none"
        document.body.classList.remove("modal-open")
      }

      const backdrop = document.querySelector(".modal-backdrop")
      if (backdrop) {
        backdrop.removeEventListener("click", this.closeModal)
        backdrop.style.pointerEvents = "none" // Disable pointer events on backdrop
      }
    },
    sortBy(column) {
      const sortableColumn = this.columns.find((col) => col.name === column && col.sortable)

      if (sortableColumn) {
        if (this.sortColumn === column) {
          this.sortDesc = !this.sortDesc
        } else {
          this.sortColumn = column
          this.sortDesc = false
        }

        // //  console.log('Sorted Calls:', this.sortedCalls)
      }
    },
    async GetFraudNotesByInteraction(company, interactionid) {
      //   const headers = {
      //     Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //     "Content-Type": "application/json",
      //   }
      try {
        //PHP
        // const resNotesbyIId = await axios.get(
        //     `https://genesys-api.gcm3.com/apiNODE/api.php?action=get_fraud_notes_by_interactionid&InteractionId=${interactionid}&company=${company}`,
        //     { headers }
        // )
        // if (resNotesbyIId.status === 200) {
        //     this.isLoadingNotes = false
        //     //  console.log('NotesCategory by Company API result = ', resNotesbyIId.data);
        //     //  console.log('interaction',interactionid)
        //     //  console.log('company',company)
        //     this.notes = resNotesbyIId.data
        //     //  console.log('GetFraudNotesByInteraction',this.notes.result)
        //     //  console.log('GetFraudNotesByInteraction resNotesbyIId.data.result', resNotesbyIId.data)
        // }
        // NODE
        const { status, data } = await api.post("getFraudNotesByInteractionId", {
          InteractionId: interactionid,
          Company: company,
        })

        if (status === 200) {
          this.isLoadingNotes = false
          this.notes.result = data
        }
      } catch (error) {
        //  console.log(error);
      }
    },
    getValueByKey(obj, key) {
      const keys = key.split(".")
      let value = obj

      for (const k of keys) {
        if (Object.prototype.hasOwnProperty.call(value, k)) {
          value = value[k]
        } else {
          value = ""
          break
        }
      }

      return value
    },
    checkAuthStatus(status) {
      if (status == "1") {
        return "Authenticated"
      } else if (status == "2") {
        return "Failed"
      } else if (status == "3") {
        return "Manually Authenticated"
      } else if (status == "4") {
        return "Manually Failed"
      } else if (status == "0") {
        return "Not Authenticated"
      }
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding: 8px;
  cursor: pointer;
}

.sortable:hover {
  background-color: #f2f2f2;
}

th.active {
  font-weight: bold;
}

.sort-indicator {
  margin-left: 4px;
}

td {
  padding: 8px;
  border-top: 1px solid #ddd;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}

h1 {
  font-size: 24px;
}

.description {
  font: -webkit-control;
}

.body-container {
  align-self: unset;
  min-width: 1100px;
}

.row {
  margin: 1.5rem 0;
}
tr td:nth-child(1) {
  text-align: right;
  display: table-cell;
  min-width: 100px; /* Minimum width */
  max-width: 200px; /* Maximum width */
  /* width: 100%; */
}

tr td:nth-child(5) {
  padding: 8px 4px !important;
}

tr th:nth-child(1) {
  /* width: 100%; */
  /* width: 131px; */
  width: 8%;
}

.img-container {
  display: inline-block;
  min-width: 100px; /* Minimum width */
  max-width: 200px; /* Maximum width */
}
.member-history-table {
  /* table-layout: fixed; */
  /* width: 100%; */
}
.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: fixed;
  z-index: 1051;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: auto;
  margin: 0;
  pointer-events: none;
}

.modal-lg {
  max-width: 800px;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #ffffff; /* Updated color */
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #fff;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  background-color: #fff; /* Updated color */
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff; /* Updated color */
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  pointer-events: auto;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
}

/* .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #ffff;
  cursor: pointer;
  z-index: 1052;
}

.close:hover {
  opacity: 0.75;
} */

.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal-backdrop.show {
  opacity: 1;
  pointer-events: auto;
}

dialog {
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 0.4rem; */

  padding: 0;
  /* width: 450px; */
  position: fixed;
  left: 0;
  top: 10%;

  background-color: #fff;
  z-index: 100;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

.dialog-header {
  margin: 0;
  margin-bottom: 16px;
  font-size: 24px;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -10;
}

.dialog-body {
  z-index: 10;
  background-color: #fff;
  padding: 2rem;
  /* border-radius: 6.4px; */
  overflow-y: auto; /* Add a vertical scroll bar when content exceeds the height */
  max-height: 80vh; /* Limit the maximum height of the dialog body */
}

</style>
