<script setup>
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
import BaseButton from "./UI/BaseButton.vue"
import BaseInputIVR from "./UI/BaseInputIVR.vue"
import BaseInputCore from "./UI/BaseInputCore.vue"
import ManageBrandBox from "./ManageBrandBox.vue"
import Header from "./Header.vue"
import api from "../api/api.js"
import { flashMessage } from "@/functions"
</script>

<template>

<div class="body-container ">
  <div class="body-container1">
    <!-- <ManageBrandBox :userData="userData" /> -->
    <header>
      <h2>IVR Configuration</h2>
    </header>
    <div class="row"></div>
    <div class="row">
      <form action="" @submit.prevent="updateIvrConfig">
        <div class="col1">
        <div class="row">
        <div class="input-wrapper">
        <label for="Authentication" class="labels" style="margin-left: 150px;">Authentication Method</label>
            </div>
            </div>

          <div class="checkbox-row">
            <div>
              <input type="checkbox" id="memberNumber" v-model="ivrDataObj.MemberNumber" checked disabled>
              <labels for="memberNumber" class="label-right">Member Number</labels>
            </div>
            <div>
              <input type="checkbox" id="accountNumber" v-model="ivrDataObj.AccountNumber" checked disabled>
              <labels for="accountNumber" class="label-right">Account Number</labels>
            </div>
            <div>
              <input type="checkbox" id="ssnTaxID" v-model="ivrDataObj.SSNTaxID" @change="updateSSNTaxID">
              <labels for="ssnTaxID" class="label-right">SSN/TaxID</labels>
            </div>
          </div>


          <div class="row">
            <label for="maxattempts" class="">MaxAttempts</label>
            <BaseInputCore type="number" min="1" max="10" v-model="ivrDataObj.MaxAttempts" 
            readonly/>
          </div>

          <!-- <div class="row">
            <label for="NumberOfDigitsDC" class="">NumberOfDigitsDC</label>
            <BaseInputCore
              type="number"
              min="1"
              max="50"
              v-model="ivrDataObj.NumberOfDigitsDC"
              id="NumberOfDigitsDC" />
          </div> -->

          <div class="row">
            <label for="NumberOfDigitsMemberNumber" class="">NumberOfDigitsMemberNumber</label>
            <BaseInputCore
              type="number"
              min="1"
              max="50"
              v-model="ivrDataObj.NumberOfDigitsMemberNumber"
              id="NumberOfDigitsMemberNumber" 
              readonly/>
          </div>

          <div class="row">
            <label for="NumberOfDigitsPIN" class="">NumberOfDigitsPIN</label>
            <BaseInputCore
              type="number"
              min="1"
              max="50"
              v-model="ivrDataObj.NumberOfDigitsPIN"
              id="NumberOfDigitsPIN" 
              readonly/>
          </div>

          <div class="row">
            <label for="numtransactionstoplay" class="">NumTransactionsToPlay</label>
            <BaseInputCore
              type="number"
              min="1"
              max="50"
              v-model="ivrDataObj.NumTransactionsToPlay"
              id="NumTransactionsToPlay" 
              readonly/>
          </div>

          <div class="row">
            <label for="MonthsForTransactions" class="">MonthsForTransactions</label>
            <BaseInputCore
              type="number"
              min="1"
              max="50"
              v-model="ivrDataObj.MonthsForTransactions"
              id="MonthsForTransactions" 
              readonly/>
          </div>
        
          <div class="row">
            <label for="RepeatMenuChar" class="">RepeatMenuChar</label>
            <BaseInputCore
              type="text"
              min="1"
              max="50"
              v-model="ivrDataObj.RepeatMenuChar"
              id="RepeatMenuChar" 
              readonly/>
          </div>

          <div class="row">
            <label for="ReturnToPreviousMenuChar" class="">ReturnToPreviousMenuChar</label>
            <BaseInputCore
              type="text"
              min="1"
              max="50"
              v-model="ivrDataObj.ReturnToPreviousMenuChar"
              id="ReturnToPreviousMenuChar" 
              readonly/>
          </div>
<!-- 
          <div class="row">
            <label for="DirectTransferToAgentChar" class="">DirectTransferToAgentChar</label>
            <BaseInputCore
              type="text"
              min="1"
              max="50"
              v-model="ivrDataObj.DirectTransferToAgentChar"
              id="DirectTransferToAgentChar" />
          </div> -->

        </div>
        <div class="col2">
          <div class="btn-container">
            <ButtonSubmit type="submit" label="Save" />
            <ButtonCancel
              type="button"
              label="Cancel"
              @click="cancelIvrConfig"
              :disabled="isDisabled" />
          </div>
        </div>
      </form>
    </div>
  </div>


  <div class="body-container2">
    <header>
      <h2>Core Configuration</h2>
      <h2>I01</h2>
    </header>
    <div class="row"></div>
    <div class="row">

      <form action="" @submit.prevent="updateCoreConfig">
        <div class="col1">

          <div class="row">
            <label for="Core" class="">Core</label>
            <BaseInputIVR 
            type="text" 
            v-model="CoreSystemObj.CoreSystemType"
             readonly/>
          </div>

          <div class="row">
            <label for="Loan" class="">Loans</label>
            <BaseInputIVR
              type="text"
              v-model="CoreDataObj.Loan"
              readonly/>
          </div>

          <div class="row">
            <label for="Savings" class="">Savings</label>
            <BaseInputIVR 
            type="text" 
            v-model="CoreDataObj.Savings" 
            readonly/>
          </div>

          <div class="row">
            <label for="Checking" class="">Checking</label>
            <BaseInputIVR
              type="text"
              v-model="CoreDataObj.Checking"
              readonly/>
          </div>

          <div class="row">
            <label for="CreditCard" class="">Credit Card</label>
            <BaseInputIVR
              type="text"
              v-model="CoreDataObj.CreditCard"
              readonly/>
          </div>

          <div class="row">
            <label for="DebitCard" class="">Debit Card</label>
            <BaseInputIVR
              type="text"
              v-model="CoreDataObj.DebitCard"
              readonly/>
          </div>    

          <div class="row">
            <label for="LOCLoans" class="">LOC Loans</label>
            <BaseInputIVR
              type="text"
              v-model="CoreDataObj.LOCloans"
              readonly/>
          </div>    

        </div>

        <div class="col2">
          <div class="btn-container">
            <ButtonSubmit type="submit" label="Save" />
            <ButtonCancel
              type="button"
              label="Cancel"
              @click="cancelCoreConfig"
              :disabled="isDisableds" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


</template>

<script>
// import axios from "axios"

// const api = axios.create({
//   baseURL: "https://genesys-api.gcm3.com/apiNODE/api_ivrconfig.php",
//   headers: {
//     Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
//     "Content-Type": "application/json",
//   },
// })

export default {
  name: "IVRConfigComponent",
  components: [ManageBrandBox, Header, ButtonSubmit, ButtonCancel, BaseInputIVR, BaseInputCore, BaseButton],
  data: () => {
    return {

      company: localStorage.getItem('companyID'),
      ivrDataObj: {
        MemberNumber: true,
        SSNTaxID: false,
        AccountNumber: true
      },
      CoreDataObj: {},
      CoreSystemObj: {
        CoreSystemType: ""
      },

      ivrTempData: {},
      coreTempData: {},

    }
  },
  props: {
    userData: Object,
    brandData: Object,
  },

  
  async mounted() {
    this.getIvrConfig()
    this.getCoreConfig(); 
    this.getCoreSystemType(); 

    this.coreTempData = { ...this.CoreDataObj };
    this.ivrTempData = { ...this.ivrDataObj };
    if (this.ivrTempData.PrimaryAuthenticationMethods) {
    this.ivrDataObj.SSNTaxID = this.ivrTempData.PrimaryAuthenticationMethods.SSNTaxID;
  }
  },

  computed: {

    isDisabled() {
    for (const prop in this.ivrDataObj) {
      if (this.ivrDataObj[prop] !== this.ivrTempData[prop]) {
        return false;
      }
    }
    return true;
  },

    isDisableds() {
    for (const prop in this.CoreDataObj) {
      if (this.CoreDataObj[prop] !== this.coreTempData[prop]) {
        return false;
      }
    }
    return true;
  },

  },

  methods: {

    updateSSNTaxID(event) {
    this.ivrDataObj.SSNTaxID = event.target.checked;
  },



async getIvrConfig() {
  try {
    const { data } = await api.post("getIVRConfigOptions", { Company: this.company });
    console.log("Retrieved data IVR:", data);

    if (typeof data.IVRConfig === 'string') {
      console.log("IVRConfig is a string:", data.IVRConfig);
      const ivrConfig = JSON.parse(data.IVRConfig);

      this.ivrTempData.MaxAttempts = ivrConfig.MaxAttempts || "";
      this.ivrTempData.NumTransactionsToPlay = ivrConfig.NumTransactionsToPlay || "";
      this.ivrTempData.MonthsForTransactions = ivrConfig.MonthsForTransactions || "";
      this.ivrTempData.NumberOfDigitsDC = ivrConfig.NumberOfDigitsDC || "";
      this.ivrTempData.NumberOfDigitsMemberNumber = ivrConfig.NumberOfDigitsMemberNumber || "";
      this.ivrTempData.NumberOfDigitsPIN = ivrConfig.NumberOfDigitsPIN || "";
      this.ivrTempData.ReturnToPreviousMenuChar = ivrConfig.ReturnToPreviousMenuChar || "";
      this.ivrTempData.RepeatMenuChar = ivrConfig.RepeatMenuChar || "";
      this.ivrTempData.DirectTransferToAgentChar = ivrConfig.DirectTransferToAgentChar || "";

      this.ivrTempData.SSNTaxID = ivrConfig.PrimaryAuthenticationMethods.SSNTaxID;
      this.ivrDataObj.SSNTaxID = this.ivrTempData.SSNTaxID;
    } else {
      console.log("IVRConfig is an object:", data.IVRConfig);
      const ivrConfig = data.IVRConfig;

      this.ivrTempData.MaxAttempts = ivrConfig.MaxAttempts || "";
      this.ivrTempData.NumTransactionsToPlay = ivrConfig.NumTransactionsToPlay || "";
      this.ivrTempData.MonthsForTransactions = ivrConfig.MonthsForTransactions || "";
      this.ivrTempData.NumberOfDigitsDC = ivrConfig.NumberOfDigitsDC || "";
      this.ivrTempData.NumberOfDigitsMemberNumber = ivrConfig.NumberOfDigitsMemberNumber || "";
      this.ivrTempData.NumberOfDigitsPIN = ivrConfig.NumberOfDigitsPIN || "";
      this.ivrTempData.ReturnToPreviousMenuChar = ivrConfig.ReturnToPreviousMenuChar || "";
      this.ivrTempData.RepeatMenuChar = ivrConfig.RepeatMenuChar || "";
      this.ivrTempData.DirectTransferToAgentChar = ivrConfig.DirectTransferToAgentChar || "";

      this.ivrTempData.SSNTaxID = ivrConfig.PrimaryAuthenticationMethods.SSNTaxID;
      this.ivrDataObj.SSNTaxID = this.ivrTempData.SSNTaxID;
    }

    this.ivrDataObj.MaxAttempts = this.ivrTempData.MaxAttempts;
    this.ivrDataObj.NumTransactionsToPlay = this.ivrTempData.NumTransactionsToPlay;
    this.ivrDataObj.MonthsForTransactions = this.ivrTempData.MonthsForTransactions;
    this.ivrDataObj.NumberOfDigitsDC = this.ivrTempData.NumberOfDigitsDC;
    this.ivrDataObj.NumberOfDigitsMemberNumber = this.ivrTempData.NumberOfDigitsMemberNumber;
    this.ivrDataObj.NumberOfDigitsPIN = this.ivrTempData.NumberOfDigitsPIN;
    this.ivrDataObj.RepeatMenuChar = this.ivrTempData.RepeatMenuChar;
    this.ivrDataObj.ReturnToPreviousMenuChar = this.ivrTempData.ReturnToPreviousMenuChar;
    this.ivrDataObj.DirectTransferToAgentChar = this.ivrTempData.DirectTransferToAgentChar;

    console.log("Updated ivrTempData and initialized ivrDataObj:", this.ivrTempData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
},

async getCoreConfig() {
  try {
    const { data } = await api.post("getIVRConfigOptionsCore", { Company: this.company });
    console.log("Retrieved data:", data);

    if (typeof data.CoreConfig === 'string') {
      console.log("CoreConfig is a string:", data.CoreConfig);
      const coreConfigObject = JSON.parse(data.CoreConfig);

      this.coreTempData.Savings = coreConfigObject.Savings || {};
      this.coreTempData.CreditCard = coreConfigObject.CreditCard || {};
      this.coreTempData.Checking = coreConfigObject.Checking || {};
      this.coreTempData.Loan = coreConfigObject.Loan || {};
      this.coreTempData.DebitCard = coreConfigObject.DebitCard || {};
      this.coreTempData.LOCloans = coreConfigObject.LOCloans || {};

    } else {
      console.log("CoreConfig is an object:", data.CoreConfig);
      const coreConfigObject = data.CoreConfig;

      this.coreTempData.Savings = coreConfigObject.Savings || {};
      this.coreTempData.CreditCard = coreConfigObject.CreditCard || {};
      this.coreTempData.Checking = coreConfigObject.Checking || {};
      this.coreTempData.Loan = coreConfigObject.Loan || {};
      this.coreTempData.DebitCard = coreConfigObject.DebitCard || {};
      this.coreTempData.LOCloans = coreConfigObject.LOCloans || {};

    }

    this.CoreDataObj.Savings = this.coreTempData.Savings;
    this.CoreDataObj.CreditCard = this.coreTempData.CreditCard;
    this.CoreDataObj.Checking = this.coreTempData.Checking;
    this.CoreDataObj.Loan = this.coreTempData.Loan;
    this.CoreDataObj.DebitCard = this.coreTempData.DebitCard;
    this.CoreDataObj.LOCloans = this.coreTempData.LOCloans;

    console.log("Updated coreTempData and initialized CoreDataObj:", this.coreTempData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
},



  async getCoreSystemType() {
    try {
    console.log("Requesting core system type for company:", this.company);

    const response = await api.post("getIVRConfigOptionsCoreSystemType", { Company: this.company });
    const data = response.data; 

    console.log("Retrieved data core:", data);

    this.CoreSystemObj.CoreSystemType = data && data.CoreSystemType ? data.CoreSystemType : "";
    console.log("CoreDataObj:", this.CoreSystemObj);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  },


  async updateCoreConfig() {
    try {
      const coreData = {};
      Object.keys(this.CoreDataObj).forEach(key => {
        let values = [];
        if (Array.isArray(this.CoreDataObj[key])) {
          values = this.CoreDataObj[key].join(',').split(',').map(value => value.trim());
        } else if (typeof this.CoreDataObj[key] === 'string') {
          values = this.CoreDataObj[key].split(',').map(value => value.trim().replace(/\s+/g, ' '));
        } else {
          console.warn(`Unexpected type for ${key}: ${typeof this.CoreDataObj[key]}`);
        }
        coreData[key] = values;
      });

      const requestData = {
        Company: this.company,
        CoreDataObj: coreData,
      };

      console.log("Request Data:", requestData);

      const { data } = await api.post("updateCoreConfigOptions", requestData);

      console.log("API Response:", data);

      this.getCoreConfig();
      flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        "Core Configuration updated successfully"
      );
      console.log("rows", data.affectedRows);
    } catch (error) {
      console.error("Error updating core configuration:", error);
    }
  },


    async updateIvrConfig() {
      try {
      const requestData = {
      Company: this.company,
      IVRConfig: JSON.stringify({
        MaxAttempts: this.ivrDataObj.MaxAttempts || "",
        NumTransactionsToPlay: this.ivrDataObj.NumTransactionsToPlay || "",
        MonthsForTransactions: this.ivrDataObj.MonthsForTransactions || "",

        NumberOfDigitsDC: this.ivrDataObj.NumberOfDigitsDC || "",
        NumberOfDigitsMemberNumber: this.ivrDataObj.NumberOfDigitsMemberNumber || "",
        NumberOfDigitsPIN: this.ivrDataObj.NumberOfDigitsPIN || "",
        ReturnToPreviousMenuChar: this.ivrDataObj.ReturnToPreviousMenuChar || "",
        RepeatMenuChar: this.ivrDataObj.RepeatMenuChar || "",
        DirectTransferToAgentChar: this.ivrDataObj.DirectTransferToAgentChar || "",

        PrimaryAuthenticationMethods: {
          SSNTaxID: this.ivrDataObj.SSNTaxID,
          MemberNumber: this.ivrDataObj.MemberNumber, 
         AccountNumber: this.ivrDataObj.AccountNumber 
        }
      }),
    };

    console.log("Request Data:", requestData);

    const { data } = await api.post("updateIVRConfigOptions", requestData);

    console.log("API Response:", data);

    this.ivrTempData = { ...this.ivrDataObj };

    this.getCoreConfig();

    flashMessage(
      this.$flashMessage,
      this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
      this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
      "IVR Configuration updated successfully"
    );
    console.log("rows", data.affectedRows);
  } catch (error) {
    console.error("Error updating IVR configuration:", error);
  }
},





sanitizeData(ivrData) {
      return ivrData.replace(/[\s,+]+/g, ",").replace(/[^0-9,]+|,$/gm, "")
    },
    cancelIvrConfig() {
      this.ivrDataObj = { ...this.ivrTempData };
    flashMessage(
      this.$flashMessage,
      this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
      this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
      "IVR Config canceled successfully."
     );
      },

    cancelCoreConfig() {
        Object.keys(this.CoreDataObj).forEach((key) => {
          this.CoreDataObj[key] = this.coreTempData[key]
        })
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "IVR Config canceled successfully."
        )
      },

  },
}
</script>


<style scoped>
.input-wrapper {
  position: relative;
}

/* .checkbox-row label {
  margin-left: 40px 
} */
.labels {
  position: absolute;
  top: 4ex;
  z-index: 1;
  left: -9.5em;
  padding: 0 5px;
  white-space: nowrap;

}

.labels::before {
  content: '';
  position: absolute;
  top: 0;
  /* left: 0PX; */
  /* right: 100px; */
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  z-index: -1;
  width: calc(274px - 100px);
  white-space: nowrap;

}



.label-right {
  margin-left: 10px;
  white-space: nowrap; 
  font-size: 1vw;

}

.checkbox-row {

  margin-left: 265px;
  width: 50%;
  margin-top: -20px;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the container */

  box-shadow: 2px 4px #ccc;
  color: rgba(0, 0, 0, 0.87);

  padding: 8px 5px;
  border-radius: 4px;
  white-space: nowrap;

  border-top: 1.9px solid black; 
  border-left: 1.9px solid black; 
  border-right: 1.9px solid #717171; 
  border-bottom: 1.9px solid #6d6d6d;  
  margin-bottom: 20px
}
.checkbox-row div{
  display: flex;
  flex-direction: row;
  gap: 0;
}
.checkbox-row div{
  display: flex;
  flex-direction: row;
  gap: 0;
}


.checkbox-row label {
  /* margin-right: 10px;
  width: 100px; 
    white-space: nowrap;  */

}

.checkbox-row input[type="checkbox"] {
  /* display: block; */
  /* width: 100%; */
  /* top: 80%;  */
  /* transform: translateY(100%); 
  transform: translateX(-260%); 
  position: relative; */
  /* margin: 0; */
}


.body-container1 .btn-container {
 margin-right: -270px; 
}
.body-container1 form .row label {
 margin-right: 5px; 
 width: 250px; 
 white-space: nowrap;
 text-align: right; 
}
 .body-container2 h2 {
 margin-left: 90px;
}
header {
 display: flex;
 justify-content: space-between;
}
form .row {
display: grid;
grid-template-columns: repeat(12, 1fr);
align-items: center;
gap: 10px;
}

label {
margin: 0;
font-size: 16px;
font-weight: 500;
text-align: right;
grid-column: 1/4;
}


input {
line-height: 1.5;
font-size: 1.1rem;
padding: 6px 6px;
/* width: 100%; */
grid-column: 4/-1; 
text-align: left; 
}


table {
 width: 75% !important;
 margin: 0 auto;
}

/* input {
 line-height: 1.5;
 font-size: 1.1rem;
 padding: 6px 6px;
 width: 100%;
 grid-column: 5/-1;

 outline: none;
 border: 1px solid black;
} */

button {
 /*  height: 32px;*/
 /*  border-radius: unset;*/
}

form {
 display: grid;
 grid-template-columns: repeat(12, 1fr);
 margin: 40px 10px 0;
 gap: 0 2rem;
}

form .row {
 display: grid;
 grid-template-columns: repeat(12, 1fr);
 align-items: center;
 gap: 10px;
}

form > button {
 /*  height: 32px;*/
}

.body-container {
 display: flex;
}

.body-container1 {
 flex: 1;
}

.body-container2 {
 flex: 1;
}
.form-group {
 grid-column: 4/11;
}
.btn-cancel {
 margin-left: 0;
}

.btn-container {
 display: flex;
 justify-content: center;
 gap: 20px;
 height: 40px;
}

.border-solid {
 border: 1px solid black;
}

.row {
 margin-bottom: 20px;
}

.col1 {
 grid-column: 1/10;
}

.col2 {
 grid-column: 1/10;
 grid-row: 2/3;
}

.btn {
 display: inline-block;
 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 vertical-align: middle;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 border: 1px solid transparent;
 padding: 0.575rem 0.75rem;
 font-size: 1rem;
 line-height: 1;
 border-radius: 0.25rem;
 transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
   border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:disabled {
 pointer-events: none;
 opacity: 0.65;
}
.btn-primary {
 color: #fff;
 background-color: #007bff;
 border-color: #007bff;
}
.btn-primary:hover {
 color: #fff;
 background-color: #0069d9;
 border-color: #0062cc;
}
.btn-danger {
 color: #fff;
 background-color: #dc3545;
 border-color: #dc3545;
}
.btn-danger:hover {
 color: #fff;
 background-color: #c82333;
 border-color: #bd2130;
}
.btn-info {
 color: #fff;
 background-color: #17a2b8;
 border-color: #17a2b8;
}
.btn-info:hover {
 color: #fff;
 background-color: #138496;
 border-color: #117a8b;
}
.btn-secondary {
 color: #fff;
 background-color: #6c757d;
 /*  border-color: #6c757d;*/
 font-size: 14px;
 border: 1px solid rgba(27, 31, 35, 0.15);
 border-radius: 20px;
}
.btn-secondary:hover {
 color: #fff;
 background-color: #5a6268;
 border-color: #545b62;
}

.btn-secondary:disabled {
 color: #fff;
 background-color: #6c757d;
 border-color: #6c757d;
}
</style>

