<script setup>
// import Header from "./Header.vue"
// import MemberInformationBox from "./MemberInformationBox.vue"
// import axios from "axios"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import BaseButton from "./UI/BaseButton.vue"
import BaseInput from "./UI/BaseInput.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
import api from "../api/api.js"
import { flashMessage } from "../functions.js"
</script>

<template>
  <div class="body-container">
    <header>
      <h2>Member Information</h2>
      <div>
        <div style="display: inline-block; margin-right: 20px" class="form">
          <BaseButton class="btn-submit" @click="mnetapplicationpop" :disabled=false
            v-if="authStatus_num === '1' || authStatus_num === '3'">Go to Account</BaseButton>
          <BaseButton v-else class="btn-submit" @click="mnetapplicationpop" :disabled=true>Go to Account</BaseButton>
        </div>

        <h2 style="display: inline-block">M01</h2>
      </div>
    </header>

    <div v-if="callerInfo && callerInfo.result" class="member-info">
      <span>
        <div>
          <span>Member Number: </span>
          <span id="label_member_number">{{ callerInfo.result.MemberNumber || "" }}</span>
          <span v-if="callerInfo.result.MemberName && callerInfo.result.MemberNumber">

            <img class="membernumber-icon" title="Valid Member Number" :src="logocheck" alt="check" />
          </span>

          <img v-if="!callerInfo.result.MemberName && callerInfo.result.MemberNumber" class="membernumber-icon"
            title="Invalid Member Number" :src="logoclose" alt="invalid" />
        </div>
        <div>
          <span>Name: </span>
          <span id="label_member_name">{{ callerInfo.result.MemberName || "" }}</span>
        </div>
      </span>

      <span>
        <div>
          <span>Status: </span>
          <b><span id="authstatus_value" :style="{ color: authStatusColor }">{{
            authStatus
          }}</span></b>

          <span v-if="authStatus_num === '0'" title="Not Yet Authenticated">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>

          <span v-if="authStatus_num === '1'" title="Authenticated Valid Credential">
            <img class="membernumber-icon" :src="logocheck" alt="check" />
          </span>

          <span v-if="authStatus_num === '2' && !callerInfo.result.FailureReason" title="Invalid Credential">
            <img class="membernumber-icon" :src="logominus" alt="invalid" />
          </span>


          <span v-show="authStatus_num === '2' && callerInfo.result.FailureReason"
            :title="callerInfo.result.FailureReason">
            <img class="membernumber-icon" :src="blackminus" alt="invalid" />
          </span>

          <span v-if="authStatus_num === '3'" title="Manually Authenticated">
            <img class="membernumber-icon" :src="logocheck" alt="check" />
          </span>

          <span v-if="authStatus_num === '4'" title="Manually Failed">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>

          <span v-if="authStatus_num === '5'" title="Invalid Status">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>


        </div>
        <div>
          <span>Reason: </span>
          <span id="label_attempttype">{{ attemptType }}</span>
        </div>
        <div style="padding: 20px 0">
          <span id="phonenum_list">{{ phonenumList }}</span>
        </div>
      </span>

      <span style="margin-left: auto">
        <!-- <div class="container">
          <div class="loader-overlay" v-if="isLoading"></div>

          <div class="form">
            <BaseInput
              type="text"
              style="max-width: 50px; padding-left: 5px; font-size: 15px"
              v-model="authToken" />
            <BaseButton class="btn-submit" @click="GetCallerInfoUsingAuthToken"
              >Get Caller Info</BaseButton
            >
          </div>
          <div class="form">
            <BaseButton class="btn-submit" @click="mnetapplicationpop">Show App</BaseButton>
          </div>
        </div> -->
      </span>
    </div>

    <div class="member-info-content">
      <div>
        <form @submit.prevent="handleSend()" method="post">
    <div class="radiolist">
      <div style="display: flex;  margin-top: 13px;">
        <input type="radio" id="member_number" name="member_type" value="member_number" v-model="selectedMemberType">
        <label for="member_number">Member #</label>
      </div>
      <div style="display: flex;  margin-top: 13px;">
        <input type="radio" id="ssn" name="member_type" value="ssn" v-model="selectedMemberType">
        <label for="ssn">SSN</label>
      </div>
    </div>
    <div>
      <BaseInput type="text" id="member_number_or_credit_card" name="member_number_or_credit_card" v-model="inputNo"
        class="form-control" style="padding-left: 10px; font-size: 16px; margin-top: 3px; width: 260px" :disabled="!selectedMemberType" />
    </div>
    <ButtonSubmit style="margin-top: 13.5px" label="Send" :disabled="isSendButtonDisabled" />
    <ButtonCancel style="margin-top: 10px" type="button" label="Cancel" @click="handleCancel('membernumber')"
      :disabled="!inputNo" />
  </form>



        <div style="margin-top: 30px">
          <table id="table" class="member-info-table">
            <caption>
              Member Information
            </caption>
            <thead>
              <tr>
                <th>Name</th>
                <th>Relationship</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="callerInfo && callerInfo.result && callerInfo.result.MemberName">
                  {{ callerInfo.result.MemberName }}
                </td>
                <td v-if="callerInfo && callerInfo.result && callerInfo.result.MemberName">
                  Primary
                </td>
                <!-- </tr>
              <tr>
              <td v-if="callerInfo && callerInfo.result && callerInfo.result.Accounts">
                  {{ callerInfo.result.Accounts.JointOwnership[0].Name }}
                </td>
                <td v-if="callerInfo && callerInfo.result && callerInfo.result.Accounts">
                  Joint OR
                </td>
              </tr> -->
              </tr>

              <template v-if="callerInfo && callerInfo.result && callerInfo.result.Accounts">
                <tr v-for="(jointOwnership, index) in uniqueAccounts" :key="index">
                  <td v-if="!isMemberNameExist(jointOwnership.Owner.toUpperCase())">
                    {{ jointOwnership.Owner.toUpperCase() }}
                  </td>
                  <td v-if="!isMemberNameExist(jointOwnership.Owner.toUpperCase())">
                    {{ jointOwnership.OwnershipCodeDescription }}
                  </td>
                </tr>
              </template>






              <!-- <tr v-for="(jointOwner, index) in getJointOwners" :key="index">
          <td>{{ jointOwner.Name }}</td>
          <td> Joint OR</td>
                </tr> -->



            </tbody>
          </table>
        </div>
        <!-- <div v-if="isDemo" style="margin-top: 20px;"> -->
        <!-- <div style="margin-top: 20px;">
          <h4>Fraud Warnings</h4>

          <textarea v-if="isGetThreshold" disabled class="form-control-textarea"
            style="font-size: 16px; width: 380px; color:red;" v-model="threshold" cols="15" rows="5"></textarea>

          <textarea v-else disabled class="form-control-textarea" style="font-size: 16px; width: 380px; color:red;"
            cols="15" rows="5"></textarea>
        </div> -->
      </div>

      <div>
        <div>
          <form method="post">
            <div>
              <h4>Authentication Status</h4>
              <div class="btn-auth-group" style="margin-top: 10px">
                <button class="btn-manually-auth" :class="{ 'btn-clicked-green': AuthclickedButton === 3 }"
                  @click.prevent="passValueAuth(3, $event)" :disabled="callerInfo.result.MemberNumber === '' ||
                    Object.keys(callerInfo.result).length === 0
                    ">
                  <span class="btn-text-large">Manually Authenticate</span>
                </button>
                <button class="btn-manually-failed" :class="{ 'btn-clicked-red': AuthclickedButton === 4 }"
                  @click.prevent="passValueAuth(4, $event)" :disabled="Object.keys(callerInfo.result).length === 0">
                  <span class="btn-text-large">Manually Fail</span>
                </button>
              </div>
            </div>

            <div v-if="isLoadingAuth">
              <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
            </div>
            <ButtonSubmit style="margin-top: 10.5px" v-if="!isLoadingAuth" class="btn-submit" type="submit" label="Save"
              @click.prevent="handleAuth()" :disabled="!isAuthVerifed" />
            <ButtonCancel style="margin-top: 10px" label="Cancel" type="button" @click="handleCancel('authstatus')"
              :disabled="!isAuthVerifed" />
          </form>
        </div>
        <div>
          <form @submit.prevent="handleNote()" style="margin-top: 2.7rem">
            <h4>Security Notes</h4>
            <div v-if="isLoadingNotes">
              <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
            </div>
            <div style="margin: 10px 0 15px" v-if="!isLoadingNotes">
              <select class="select-categories form-control-select" name="category" v-model="category">
                <option value="" disabled selected hidden>Note Category</option>
                <option v-for="category in noteCategories" :key="category" :value="category">
                  {{ category }}
                </option>
              </select>
            </div>
            <div>
              <label for="">Notes</label>
              <textarea class="form-control-textarea" style="padding-left: 10px; font-size: 16px; width: 380px"
                v-model="notes" cols="15" rows="5"></textarea>
            </div>
            <!-- <BaseButton style="margin-top: 5px" class="btn-submit" :isDisabled="isSaveEnabled">Save</BaseButton> -->
            <ButtonSubmit style="margin-top: 10px" label="Save" :disabled="isSaveEnabled" />
            <ButtonCancel type="button" style="margin-top: 10px" label="Cancel" @click="handleCancel('notecategory')"
              :disabled="isSaveEnabled" />
          </form>
        </div>
      </div>

      <div style="margin-top: 155px">
        <div v-if="!isSikorsky " class="warning-code-col">
        <!-- <div class="warning-code-col"> -->

          <table id="table" class="warning-code-table">
            <caption>
              Warning Code
            </caption>
            <thead>
              <tr>
                <th style="min-width: 110px">Warning Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <!-- <div v-if="isLoadingWarningCode">
            <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
          </div> -->
            <tbody v-if="symitarWarningCodes && symitarWarningCodes.length && !isLoadingWarningCode">
              <tr v-for="codesGroup in symitarWarningCodes" :key="codesGroup[0]?.WarningNumber">
                <td style="text-align: right; width: 40px" v-if="codesGroup && codesGroup.length && codesGroup[0]">
                  {{ codesGroup[0].WarningNumber }}
                </td>
                <td v-if="codesGroup && codesGroup.length && codesGroup[0]">
                  {{ codesGroup[0].WarnDescription }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- modal mnet core client  -->
    <div class="modal" v-show="isOpenMnet" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img class="modal-image" :src="logoPath" alt="MNET Core Access" />
      </div>
    </div>




  </div>
</template>

<script>
export default {
  name: "MemberInformation",

  data: () => ({
    company: localStorage.getItem('companyID'),
    setColor: "#007bff",
    setSize: "50px",
    isLoading: true,
    isLoadingWarningCode: true,
    isLoadingNotes: false,
    isLoadingAuth: false,
    phonenumList: "",
    category: "",
    notes: "",
    inputNo: "",
    authToken: localStorage.getItem("authToken"),
    companyID: "",
    authResult: "",
    AuthclickedButton: null,
    userWarningCodes: [],
    symitarWarningCodes: [],
    noteCategories: [], // Store the note categories
    authStatus: "",
    authStatusColor: "",
    attemptType: "",
    authStatus_num: "",
    isSikorsky: false,
    selectedMemberType: null,
    // inputNo: '',
    pnum: "",
    callerInfo: {
      status: "",
      message: "",
      result: {},
    },
    authCall: {
      status: "",
      message: "",
      result: {},
    },
    updateCall: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    updateCallerInfo: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    warningCodes: {
      status: "",
      num_rows: "",
      result: [],
    },
    uniqueAccounts: {},
    isOpenMnet: false,
    logoPath: require("@/assets/mnetCoreClient.jpg"),
    logocheck: require("@/assets/check.png"),
    logoclose: require("@/assets/close.png"),
    logominus: require("@/assets/minus.png"),
    blackminus: require("@/assets/blackminus.png"),

    MaxNumCallsCaller: null,
    NumHoursCaller: null,
    // isGetThreshold: false,
    isDemo: false,
    callerID: null,
    // threshold: "",
    localStorageLoginUp: localStorage.getItem("passLoginUp"),
    parsedLoginUp: "",




  }),
  props: {
    userData: Object,
    brandData: Object,
  },
  async mounted() {
    if(this.localStorageLoginUp){
      this.parsedLoginUp = JSON.parse(this.localStorageLoginUp);
    }


    if(this.company == '3d2a2858-3556-49a7-85d0-8ffa16ae5c32'){
      this.isSikorsky = true
    }
    console.log("Callerinfo", this.callerInfo.result.data)
    // console.log("joint", this.JointOwnersData)

    this.setColor = this.brandData.accent_color1
    console.log("brandData", this.brandData.main_color1)

    console.log(this.company);
    if (this.$route.query.AuthToken) {
      this.authToken = this.$route.query.AuthToken
    } else {
      this.authToken = localStorage.getItem("authToken")
    }
    //await this.isDemoURL()
    await this.GetCallerInfoUsingAuthToken()
    // await this.getThreshold()
    // await this.getThresholdCounter()
    await this.fetchNotesCategory()

  },
  computed: {
    /* filteredWarningCodes() {
      return this.warningCodes.result.filter((code) => this.userWarningCodes.includes(code.WarningCode))
    },*/
    // getJointOwners() {
    //   return this.callerInfo.result.Accounts.JointOwnership.map(owner => ({
    //     Name: owner.Name
    //   }))
    // },
    isSendButtonDisabled() {
      return !this.inputNo || !this.selectedMemberType;
    },
     


    isSendVerified() {
      return !this.inputNo.trim() || Object.keys(this.callerInfo.result).length === 0
    },
    isAuthVerifed() {
      return this.AuthclickedButton === 3 || this.AuthclickedButton === 4
    },
    isSaveEnabled() {
      if (Object.keys(this.callerInfo.result).length <= 0) {
        return true
      } else {
        if (this.category || this.notes) {
          return false
        }
      }
      return true
    },
  },



  methods: {

    isMemberNameExist(memberName) {
      return (
        this.callerInfo.result &&
        this.callerInfo.result.MemberName &&
        this.callerInfo.result.MemberName.toUpperCase() === memberName
      );
    },


    async isDemoURL() {
      const currentURL = window.location.href;
      const baseURL = currentURL.split('#')[0].replace(/\/$/, ''); // Remove trailing slash

      if (this.authToken) {
        // if (baseURL == "http://localhost:8080") {
        if (baseURL == "https://demo38web.core-access.com" || baseURL == "https://sikorskyweb.core-access.com") {
          this.isDemo = true
        }
        else {
          this.isDemo = false
        }
      }
    },
    // async getThreshold() {
    //   try {
    //     const result = await api.get(`update_threshold/${this.company}`)

    //     if (result.data.length > 0) {
    //       this.MaxNumCallsCaller = result.data[0].MaxNumCallsCaller
    //       this.NumHoursCaller = result.data[0].NumHoursCaller
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },


    // async getThresholdCounter() {
    //   try {

    //     const computedNumHoursCaller = this.NumHoursCaller
    //     const phoneNumberWithoutTel = this.callerID.replace(/^tel:/, '');

    //     const data = {
    //       "Company": this.company,
    //       "CallerID": phoneNumberWithoutTel,
    //       "NumHoursCaller": computedNumHoursCaller,
    //     }
    //     // const result = await api.post(`http://localhost:443/get-threshold-counter`, data)
    //     const result = await api.post(`get-threshold-counter`, data)
    //     console.log("result");
    //     console.log(result.data);

    //     if (result.data.length > 0) {
    //       // if (result.data[0].countIndex == 0) {
    //       if (result.data[0].countIndex >= this.MaxNumCallsCaller) {
    //         if (this.authToken) {
    //           this.threshold = `Global Warning: This caller ID has called ${this.MaxNumCallsCaller} or more times in the last ${this.NumHoursCaller} hours.`
    //           this.isGetThreshold = true
    //         }
    //         else {
    //           this.isGetThreshold = false
    //         }
    //       }
    //     }

    //   } catch (error) {
    //     console.log(error)
    //   }
    // },


    closeModal() {
      this.isOpenMnet = false
    },
    mnetapplicationpop() {
      if (this.pnum) {
        window.location.href = 'mnetpop:' + this.pnum;
        console.log('test mnetpop:',this.pnum);
      } else {
        console.log('Please provide a value for pnum');
      }
    },

    episyspop() {
      const memberNumber = localStorage.getItem("MemberNumber") || ""
      window.location.href = `EpisysPop:${memberNumber}`
    },


async handleSend() {
  try {
    if (this.selectedMemberType === 'member_number') {
      const res = await api.get(`/v2/GetAccountByMemberNumber?Company=${this.company}&AuthToken=${this.authToken}&CallerID=${this.callerID}&MemberNumber=${this.inputNo}`);
      if (res.status === 200 && res.data.Response == true) {
          this.callerInfo.result.MemberNumber = res.data.ResponseData.MemberNumber;
          this.callerInfo.result.MemberName = res.data.ResponseData.MemberName;
          flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Updating caller info based on member number."
        )
        console.log("membernumber", this.callerInfo.result)
      } 
      else{
          flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Member Not found"
          )
    }
    }

    
    if (this.selectedMemberType === 'ssn') {
      const res = await api.get(`/v2/GetAccountBySSN?Company=${this.company}&AuthToken=${this.authToken}&CallerID=${this.callerID}&TaxId=${this.inputNo}`);
       if (res.status === 200 && res.data.Response == true) {
          this.callerInfo.result.MemberNumber = res.data.ResponseData.MemberNumber;
          this.callerInfo.result.MemberName = res.data.ResponseData.MemberName;
          flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Updating caller info based on TaxId."
        )
        console.log("TaxId", this.callerInfo.result)
      }
      else{
          flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Member Not found"
          )
    }
  }
  } catch (error) {
    console.log(error);
  }
  this.inputNo = '';
  this.selectedMemberType = null;
},












    // NEXT
    async handleAuth() {
      this.isLoadingAuth = true
      this.isLoading = true
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        //NODE START
        let FailureReason = ""
        let SuccessOrFail = ""

        if (this.AuthclickedButton === 3) {
          // Handle Manually Authenticated logic
          FailureReason = null
          SuccessOrFail = this.AuthclickedButton.toString()
        } else if (this.AuthclickedButton === 4) {
          // Handle Manually Failed logic
          FailureReason = "Manually Failed by Agent"
          SuccessOrFail = this.AuthclickedButton.toString()
        }

        const resCallerInfo = await api.get(`GetCallerInfo/${this.authToken}/${this.company}`)

        if (resCallerInfo.status === 200) {
          this.callerID = resCallerInfo.data.CallerID
          this.callerInfo.result = resCallerInfo.data
          this.authCall = await this.AddAuthCall(
            FailureReason,
            SuccessOrFail,
            this.callerInfo.result
          )
          console.log(this.authCall)
          if (this.authCall.affectedRows === 1) {
            this.updateCall = await this.UpdateCallsByAuthToken(
              FailureReason,
              SuccessOrFail,
              this.callerInfo.result,
              this.authToken
            )
            console.log(SuccessOrFail)
            if (this.updateCall.affectedRows === 1) {
              this.updateCallerInfo = await this.UpdateCallerInfo(SuccessOrFail, this.authToken)

              if (this.updateCallerInfo.affectedRows === 1) {
                const resCallerInfoUpdated = await api.get(
                  `GetCallerInfo/${this.authToken}/${this.company}`
                )

                this.callerInfo.result = resCallerInfoUpdated.data
                this.readCallerAuthData(
                  this.callerInfo.result.AuthStatus,
                  this.callerInfo.result.AttemptType,
                  this.callerInfo.result.PhoneNumbers,
                  this.callerInfo.result.CallerID
                )
                this.isLoadingAuth = false
                this.isLoading = false
                this.AuthclickedButton = 0

                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
                  this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
                  "Authentication status successfully updated."
                )
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleNote() {
      this.isLoading = true
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        const resCallerInfo = await api.get(`GetCallerInfo/${this.authToken}/${this.company}`)

        this.callerInfo.result = resCallerInfo.data

        const body = {
          Company: this.company,
          Name: this.callerInfo.result.MemberName,
          MemberNumber: this.callerInfo.result.MemberNumber,
          PhoneNumber: this.callerInfo.result.CallerID,
          InteractionId: this.callerInfo.result.InteractionId,
          NoteCategory: this.category,
          NoteText: this.notes,
          CreatedByAgent: this.callerInfo.result.AgentName,
          AgentID: this.callerInfo.result.AgentId,
          AgentName: this.callerInfo.result.AgentName,
        }

        // const resAddFraudnotes = await axios.post(
        //   //GOODS NA TO
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=new_fraud_notes`,
        //   body,
        //   { headers }
        // )
        // const resAddFraudnotes = await api.post("addFraudNotes", body)
        const resAddFraudnotes = await api.post("addFraudNotes", body)

        console.log('response add fraud',resAddFraudnotes)
        if (resAddFraudnotes.status === 200) {
          this.isLoading = false
          this.category = ""
          this.notes = ""

          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Security note successfully added."
          )
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Security note not added."
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    
    async GetCallerInfoUsingAuthToken() {
  this.isLoading = true;
  this.isLoadingWarningCode = true;
  try {
    const resCallerInfo = await api.get(`GetCallerInfo/${this.authToken}/${this.company}`);

    if (resCallerInfo.status === 200) {
      console.log("ResData", resCallerInfo.data);

      if (resCallerInfo.data.Accounts !== null) {
        let originalAccounts = [];
        let accountObject = {};
        if (typeof resCallerInfo.data.Accounts.Accounts === 'undefined') {
          const accountsColumn = resCallerInfo.data.Accounts;
          accountObject = JSON.parse(accountsColumn);
          const accountValue = accountObject.Accounts;
          originalAccounts = accountValue;
          this.pnum = accountObject.PersonNumber;
        } else {
          originalAccounts = resCallerInfo.data.Accounts.Accounts;
          this.pnum = resCallerInfo.data.Accounts.PersonNumber;
        }

        const uniqueNamesSet = new Set();
        const uniqueAccounts = originalAccounts.filter((account) => {
          const upperCaseOwner = account.Owner.toUpperCase();
          if (!uniqueNamesSet.has(upperCaseOwner)) {
            uniqueNamesSet.add(upperCaseOwner);
            return true;
          }
          return false;
        });

        if (typeof resCallerInfo.data.Accounts.Accounts === 'undefined') {
          this.uniqueAccounts = uniqueAccounts;
        } else {
          resCallerInfo.data.Accounts.Accounts = uniqueAccounts;
          this.uniqueAccounts = resCallerInfo.data.Accounts.Accounts;
        }
      } else {
        console.log("Accounts data is null.");
      }

      this.callerID = resCallerInfo.data.CallerID;
      this.isLoading = false;
      if (resCallerInfo.statusText === "OK") {
        if (resCallerInfo.data) {
          this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length);
          this.callerInfo.status = resCallerInfo.status;
          this.callerInfo.result = resCallerInfo.data;

          this.readCallerAuthData(
            this.callerInfo.result.AuthStatus,
            this.callerInfo.result.AttemptType,
            this.callerInfo.result.PhoneNumbers,
            this.callerInfo.result.CallerID
          );

          this.userWarningCodes = resCallerInfo.data.WarningCodes;
          localStorage.setItem("authToken", this.authToken);
          localStorage.setItem("MemberNumber", this.callerInfo.result.MemberNumber);
          console.log('this.callerInfo.result', this.callerInfo.result);
          const warningCodesArray = this.callerInfo.result.WarningCodes.split(",");

          for (const code of warningCodesArray) {
            await this.populateWarningCodes(code);
          }
        }
      }
      this.isLoadingWarningCode = false;
    }
  } catch (error) {
    console.log(error);
  }
},




    // async populateWarningCodes(code) { // commented emil 02022024
    //   // const headers = {
    //   //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
    //   //   "Content-Type": "application/json",
    //   // }

    //   try {
    //     const body = {
    //       Company: this.company,
    //       WarningNumber: code,
    //       AuthToken: this.authToken,
    //     }
    //     // /GetWarningSelectFields
    //     // const resSymitarWarningCodes = await axios.post(
    //     //   `https://genesys-api.gcm3.com/apiNODE/GetWarningSelectFields.php`,
    //     //   body,
    //     //   { headers }
    //     // )
    //     const resSymitarWarningCodes = await api.post("GetWarningSelectFields", body)

    //     if (resSymitarWarningCodes.status === 200) {
    //       if (resSymitarWarningCodes.data.Warnings) {
    //         this.symitarWarningCodes.push(resSymitarWarningCodes.data.Warnings)
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    async fetchNotesCategory() {
      this.isLoadingNotes = true
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        //PHP
        // const resNotesCategory = await axios.get(
        //   "https://genesys-api.gcm3.com/apiNODE/api_notescategory.php?action=get_notes_category_by_id&Company=1285",
        //   { headers }
        // )
        // if (resNotesCategory.status === 200) {
        //   this.noteCategories = resNotesCategory.data.result.map((item) => item.NoteCategory)
        //   console.log("NotesCategory by Company API result = ", resNotesCategory.data.result)
        //   console.log(this.noteCategories)
        //   this.isLoadingNotes = false
        // }
        // NODE START
        const { status, data } = await api.post("getNoteCategory", { Company: this.company })

        if (status === 200) {
          this.noteCategories = data.map((item) => item.NoteCategory)
          this.isLoadingNotes = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    passValueAuth(value, event) {
      // Here you can access the passed value and perform any necessary operations
      console.log(value) // Output the value in the console for demonstration purposes
      this.AuthclickedButton = value

      if (value === 3) {
        // Manually Authenticated
        this.AuthclickedButton = 3
      } else if (value === 4) {
        // Manually Failed
        this.AuthclickedButton = 4
      }
      event.preventDefault()
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "")
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let intlCode = match[1] ? "+1 " : ""
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
      }
      return str
    },
    readCallerAuthData(authStatusNum, attemptNum, PhoneNumbers, CallerID) {
      if (authStatusNum === "0") {
        this.authStatus = "Not Authenticated"
        this.authStatusColor = "red"
        this.authStatus_num = "0"
      } else if (authStatusNum === "1") {
        this.authStatus = "Authenticated"
        this.authStatusColor = "green"
        this.authStatus_num = "1"
      } else if (authStatusNum === "2") {
        this.authStatus = "Not Authenticated"
        this.authStatusColor = "red"
        this.authStatus_num = "2"
      } else if (authStatusNum === "3") {
        this.authStatus = "Manually Authenticated"
        this.authStatusColor = "black"
        this.authStatus_num = "3"
      } else if (authStatusNum === "4") {
        this.authStatus = "Manually Failed"
        this.authStatusColor = "red"
        this.authStatus_num = "4"
      } else {
        this.authStatus = "Invalid Status"
        this.authStatusColor = "red"
        this.authStatus_num = "5"
      }

      if (attemptNum === "0") {
        this.attemptType = "Not Attempted"
      } else if (attemptNum === "1") {
        this.attemptType = "Attempted"
      } else if (attemptNum === "2") {
        this.attemptType = "Manually Attempted"
      } else {
        this.attemptType = "Invalid Attempt Value"
      }

      try {
        const formattedCallerID = this.formatPhoneNumber(CallerID)
        const formattedPhoneNumbers = PhoneNumbers.split(",").map((num) =>
          this.formatPhoneNumber(num.trim())
        )
        const enrolledNumbers = [...new Set(formattedPhoneNumbers)]
        // console.error("enrolledNumbers:", enrolledNumbers[0])

        if (formattedCallerID) {
          if (enrolledNumbers.includes(formattedCallerID)) {
            this.phonenumList = `The CallerID, ${formattedCallerID}, is found. Enrolled numbers are: ${enrolledNumbers.join(
              ", "
            )}`
          }
          else if (enrolledNumbers[0] == "null") {
            this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. Phone List is empty`
          }
          else {
            this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. Enrolled numbers are: ${enrolledNumbers.join(
              ", "
            )}`
          }
        } else {
          if (enrolledNumbers.length > 0) {
            this.phonenumList = `The CallerID is empty. Enrolled numbers are: ${enrolledNumbers.join(
              ", "
            )}`
          } else {
            this.phonenumList = "The CallerID is empty and Phone List is empty"
          }
        }
      } catch (error) {
        const formattedCallerID = this.formatPhoneNumber(CallerID)
        this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. Phone List is empty`
        console.error("Error:", error)
      }
    },

    async authenticateMethod(charCount, inpputedNo, callerID, authToken, companyID) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      // let authenticationMethod = 0
      // let creditCardNumber = null
      let memberNumber = null
      if (charCount === 16) {
        console.log("credit card detected")
        // authenticationMethod = 2
        // creditCardNumber = inpputedNo
      } else if (charCount !== 9) {
        console.log("member number detected")
        // authenticationMethod = 0
        memberNumber = inpputedNo
      } else {
        // creditCardNumber = inpputedNo
      }
      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/Authenticate.php`,
        //   {
        //     AuthenticationMethod: authenticationMethod,
        //     CreditCardNumber: creditCardNumber,
        //     MemberNumber: memberNumber,
        //     CallerID: callerID,
        //     AuthToken: authToken,
        //     Company: companyID,
        //   },
        //   { headers }
        // )
        const res = await api.post("MemberAppHandleSend", {
          MemberNumber: memberNumber,
          CallerID: callerID,
          AuthToken: authToken,
          Company: companyID,
        })

        if (res.status === 200) {
          if (res.data.Response == "YES" && res.data.WarningCodes) {
            console.log("Authenticate.php result = ", res.data)
            this.userWarningCodes = res.data.WarningCodes.split(",")
            console.log("User warning codes: ", this.userWarningCodes)
            return res.data
          } else {
            return res.data
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async AddAuthCall(FailureReason, SuccessOrFail, CallerInfo) {
      console.log("FailureReason", FailureReason)
      console.log("SuccessOrFail", SuccessOrFail)
      console.log("CallerInfo", CallerInfo)
      console.log("AgentId", CallerInfo.AgentId)
      console.log("AgentName", CallerInfo.AgentName)
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=new_auth_call`,
        //   {
        //     MemberNumber: CallerInfo.MemberNumber,
        //     MemberName: CallerInfo.MemberName,
        //     CallerID: CallerInfo.CallerID,
        //     AuthMethod: 2,
        //     SuccessOrFail: SuccessOrFail,
        //     Company: "1285",
        //     InteractionId: CallerInfo.InteractionId,
        //     FailureReason: FailureReason,
        //     CreditCardNumber: CallerInfo.CreditCardNumber,
        //     AgentID: CallerInfo.AgentId,
        //     AgentName: CallerInfo.AgentName,
        //   },
        //   { headers }
        // )
        const res = await api.post("insertAuthCalls", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          CallerID: CallerInfo.CallerID,
          AuthMethod: 2,
          SuccessOrFail: SuccessOrFail,
          Company: this.company,
          InteractionId: CallerInfo.InteractionId,
          FailureReason: FailureReason,
          CreditCardNumber: CallerInfo.CreditCardNumber,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        })

        console.log(res)

        if (res.status === 200) {
          console.log("AddAuthCalls", res.data)
          return res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async UpdateCallsByAuthToken(FailureReason, SuccessOrFail, CallerInfo, AuthToken) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=update_calls`,
        //   {
        //     MemberNumber: CallerInfo.MemberNumber,
        //     MemberName: CallerInfo.MemberName,
        //     AuthToken: AuthToken,
        //     AuthStatus: SuccessOrFail,
        //     FailureReason: FailureReason,
        //     AgentID: CallerInfo.AgentId,
        //     AgentName: CallerInfo.AgentName,
        //   },
        //   { headers }
        // )

        const res = await api.post("updateCallsAuthStatus", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          AuthToken: AuthToken,
          AuthStatus: SuccessOrFail,
          FailureReason: FailureReason,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        })

        if (res.status === 200) {
          console.log("update_calls result = ", res.data)
          return res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async UpdateCallerInfo(SuccessOrFail, AuthToken) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=update_caller_info`,
        //   {
        //     AuthStatus: SuccessOrFail,
        //     AttemptType: 2,
        //     IndexNum: AuthToken,
        //   },
        //   { headers }
        // )
        console.log(SuccessOrFail)
        const res = await api.post("updateCallerInfoSetAuthStatus", {
          AuthStatus: SuccessOrFail,
          // AttemptType: 2,
          IndexNum: AuthToken,
        })

        if (res.status === 200) {
          console.log("update_caller_info result = ", res.data)
          return res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCancel(value) {
      switch (value) {
        case "membernumber":
          this.inputNo = ""
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Member number canceled successfully"
          )
          break
        case "authstatus":
          this.AuthclickedButton = ""
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Authentication status canceled successfully"
          )
          break
        case "notecategory":
          this.notes = ""
          this.category = ""
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Note Category canceled successfully"
          )
          break
      }
   this.inputNo = '';
  this.selectedMemberType = null;
    },
  },
}
</script>

<style scoped>

.radiolist{
  display: flex;
}
.radiolist div{
  padding-right: 1rem;
  padding-bottom: .5rem;
}
.radiolist div input[type="radio"]{
  margin: 0 !important;
  margin-right: 6px !important;
  padding: 0 !important;
  width: auto;
}
.member-info-content {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 400px) 45%;
  column-gap: 1.5rem;
}

h4 {
  margin: 10px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

.member-number-label {
  margin: 10px 0;
}

header {
  display: flex;
  justify-content: space-between;
}

label {
  font-size: 1rem;
  margin: 0;
}

span {
  font-size: 1rem;
}

input {
  width: 250px;
  padding: 0.7rem 0;
  margin: 0.5rem 0;
}

table {
  margin: 14px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

select {
  display: block;
  width: 100%;
}

textarea {
  display: block;
  width: 100%;
}

.solid-border {
  border: 1px solid black;
}

.member-info {
  display: flex;
}

.member-info span:first-of-type {
  width: 420px;
}

.member-info-container {
  display: grid;
  grid-template-columns: 750px 1fr;
}

.member-info-table {
  width: 400px !important;
  display: flex;
  flex-direction: column;
}

.member-info-table tr {
  display: flex;
}

.member-info-table th,
.member-info-table td {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}

.member-number-label {
  display: block;
}

.btn-auth-group {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}


.btn-manually-auth,
.btn-manually-failed {
  width: 49%;
  height: 50px;
  background-color: #a2a7ab;
  box-shadow: 2px 4px #ccc;
  border-radius: 20px;
  border: 0;
}

.btn-save {
  margin-top: 7px;
}

.warning-code-col {
  align-self: center;
  padding-left: 2.7rem;
}

.border-black {
  border: 1px solid black;
}

.description {
  font: -webkit-control;
}

.body-container {
  align-self: unset;
  /* height: calc(100vh - 16vh); */
  height: 790px;
  min-width: 1360px;
}

.row {
  margin-bottom: 1rem;
}

.col {
  display: grid;
}

.col>div:nth-of-type(2) {
  justify-self: flex-end;
  height: 44px;
}

.col-left {
  display: inline-block;
  width: 400px;
  float: left;
}

.col-right {
  display: inline-block;
}

.select-categories {
  font-size: medium;
  padding: 5px;
}

.btn-clicked-green {
  background-color: #33cc66;
}

.btn-clicked-red {
  background-color: red;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.form {
  display: flex;
  align-items: center;
}

.button-disabled {
  /* Apply your disabled button styles here */
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity/darkness as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Adjust the z-index as needed */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text-large {
  font-size: 15px;
  color: white;
}

.btn {
  margin: 0 5px;
  padding: 7px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  width: 70px;
}

.btn-cancel {
  margin-left: 10px;
}

.warning-code-table {
  width: fit-content !important;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  z-index: 2;
  /* Higher than the overlay */
  text-align: center;
}

.modal-image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  /* Add this to show a pointer cursor on the image */
}

.membernumber-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  /* Add this to show a pointer cursor on the image */
}



.close {
  position: absolute;
  top: 100px;
  /* Adjust the top position as needed */
  right: 190px;
  /* Adjust the right position as needed */
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}
</style>
