<script setup>
import api from "../api/api";
// import Swal from "sweetalert2";
// import { flashMessage } from "../functions.js";
import ButtonSubmit from "./ButtonSubmit.vue"
import moment from 'moment';
import 'moment-timezone'; // Importing moment-timezone

</script>

<template>
  <ButtonSubmit 
    label="Refresh"
    type="submit"
    style="margin-bottom: 10px;"
    @click="getData()"
  />
  <table id="table">
    <thead>
      <tr>
        <th
          :style="{ backgroundColor: brandData.main_color2 }"
          v-for="head in table_headers"
          :key="head"
        >
          <a
            @click="sortByHeader(head, individualData)"
            style="
              display: flex;
              flex-direction: row;
              cursor: pointer;
            "
          >
            {{
              head == 'organization'
                ? 'Organization'
                : head == 'email'
                ? 'Email'
                : head == 'logged_in'
                ? 'Logged-In'
                : head == 'startup_screen'
                ? 'Startup Screen'
                : head == 'first_name'
                ? 'First Name'
                : head == 'last_name'
                ? 'Last Name'
                : head == 'roles'
                ? 'Roles'
                : head == 'added'
                ? 'Added'
                : head == 'last_login'
                ? 'Last Login'
                : null
            }}
            <div class="sort-icon" :class="{ active: sortColumn === head }">
              <a v-if="sortDirection === 'asc' && sortColumn === head">
                <i class="fa-solid fa-sort-down"></i>
              </a>
              <a v-else-if="sortDirection === 'desc' && sortColumn === head">
                <i class="fa-solid fa-sort-up"></i>
              </a>
            </div>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="input-container">
            <input
            type="text"
            placeholder="org..."
            v-model="searchOrg"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="textarea"
              placeholder="email..."
              style=""
              v-model="searchEmail"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="logged-in..."
              style="min-width: 10px"
              v-model="searchLoggedIn"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="startup screen..."
              style=""
              v-model="searchStartupScreen"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="first name..."
              style=""
              v-model="searchFirstName"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="last name..."
              style=""
              v-model="searchLastName"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="roles..."
              style=""
              v-model="searchRoles"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              class="input1"
              type="datetime-local"
              id="AddedDate"
              style=""
              v-model="searchAdded"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              class="input2"
              type="datetime-local"
              id="LastLoginDate"
              style=""
              v-model="searchLastLogin"
            />
          </div>
        </td>
      </tr>
      <tr v-for="(item, index) in filteredAdminDashboard" :key="index">
        <td>{{ item.organization }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.logged_in }}</td>
        <td>{{ item.startup_screen }}</td>
        <td>{{ item.first_name }}</td>
        <td>{{ item.last_name }}</td>
        <td>{{ item.roles }}</td>
        <td>{{ readDate(item.added) }}</td>
        <td>{{ readDate(item.last_login) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AdminDashboardBox",
  component: [ButtonSubmit],
  props: ["buttonLabel", "userData", "brandData"],
  data: () => ({
    table_headers: [
      "organization",
      "email",
      "logged_in",
      "startup_screen",
      "first_name",
      "last_name",
      "roles",
      "added",
      "last_login"
    ],
    individualData: [],
    sortDirection: 'asc',
    sortColumn: '',

    searchOrg: '',
    searchEmail: '',
    searchLoggedIn: '',
    searchStartupScreen: '',
    searchFirstName: '',
    searchLastName: '',
    searchRoles: '',
    searchAdded: '',
    searchLastLogin: '',
    localStorageLoginUp: localStorage.getItem("passLoginUp"),
    parsedLoginUp: "",
  }),
  mounted() {
    if(this.localStorageLoginUp){
      this.parsedLoginUp = JSON.parse(this.localStorageLoginUp);
    }

    this.lastLoginChecker()
    this.getData()
    
  },
  methods: {
    async lastLoginChecker(){
      try {
      const res = await api.put(`/lastLoginChecker`);
        if (res.status === 200) {

          console.log("success last login updated");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getData(){
      try {
      const res = await api.get(`/individualsNEW/${this.userData.org_id}`);
        if (res.status === 200) {
          this.individualData = res.data;
          console.log('ind data to display:', this.individualData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    readDate(date) {
      // const dateObj = new Date(data);
      // const formattedDateString = dateObj.toLocaleString();
      // return moment(formattedDateString).format("YYYY-MM-DD HH:mm:ss")
      return moment(date).tz(this.parsedLoginUp.time_zone).format("YYYY-MM-DD HH:mm:ss");
    },
    formatDateTime(date) {
      if (!date) return '';

      const localDate = new Date(date);
      const year = localDate.getFullYear();
      const month = `0${localDate.getMonth() + 1}`.slice(-2);
      const day = `0${localDate.getDate()}`.slice(-2);
      const hours = `0${localDate.getHours()}`.slice(-2);
      const minutes = `0${localDate.getMinutes()}`.slice(-2);

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    sortByHeader(header, arr) {
      console.log(arr)
      // Determine the sort direction based on whether the header is currently sorted
      if (this.sortColumn === header) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortColumn = header
        this.sortDirection = 'asc'
      }

      // Use the sort() method of the array to sort the data based on the current sort column and direction
      arr.sort((a, b) => {
        if (a[header] < b[header]) {
          return this.sortDirection === 'asc' ? -1 : 1
        }
        if (a[header] > b[header]) {
          return this.sortDirection === 'asc' ? 1 : -1
        }
        return 0
      })
    },
  },
  computed: {
    filteredAdminDashboard() {
      if (!this.individualData || !this.individualData) {
        return [];
      }

      const searchOrg = this.searchOrg ? this.searchOrg.toLowerCase().trim() : '';
      const searchEmail = this.searchEmail ? this.searchEmail.toLowerCase().trim() : '';
      const searchLoggedIn = this.searchLoggedIn ? this.searchLoggedIn.toLowerCase().trim() : '';
      const searchStartupScreen = this.searchStartupScreen ? this.searchStartupScreen.toLowerCase().trim() : '';
      const searchFirstName = this.searchFirstName ? this.searchFirstName.toLowerCase().trim() : '';
      const searchLastName = this.searchLastName ? this.searchLastName.toLowerCase().trim() : '';
      const searchRoles = this.searchRoles ? this.searchRoles.toLowerCase().trim() : '';
      const searchAdded = this.searchAdded ? new Date(this.searchAdded) : null;
      const searchLastLogin = this.searchLastLogin ? new Date(this.searchLastLogin) : null;

      return this.individualData.filter(item =>
        (searchOrg === "" || (item.organization?.toLowerCase() ?? '').includes(searchOrg)) &&
        (searchEmail === "" || (item.email?.toLowerCase() ?? '').includes(searchEmail)) &&
        (searchLoggedIn === "" || (item.logged_in?.toLowerCase() ?? '').includes(searchLoggedIn)) &&
        (searchStartupScreen === "" || (item.startup_screen?.toLowerCase() ?? '').includes(searchStartupScreen)) &&
        (searchFirstName === "" || (item.first_name?.toLowerCase() ?? '').includes(searchFirstName)) &&
        (searchLastName === "" || (item.last_name?.toLowerCase() ?? '').includes(searchLastName)) &&
        (searchRoles === "" || (item.roles?.toLowerCase() ?? '').includes(searchRoles)) &&
        ((searchAdded === null) || (item.added && new Date(item.added) > searchAdded)) &&
        ((searchLastLogin === null) || (item.last_login && new Date(item.last_login) > searchLastLogin))
      );
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
* {
/* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */ 
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
/* The rule below is not implemented in browsers yet */
  -o-user-select: none;
/* The rule below is implemented in most browsers by now */
  user-select: none;
}
@media only screen and (min-width: 280px) and (max-width: 900px) {
  .body-form-container {
    font-size: 2.5vw;
    background: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
  }
  #table {
    font-size: 2vw;
    border-collapse: collapse;
    word-break: break-word;
  }
}
#table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border: 1px solid black;
}

#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
  /* word-break: word-wrap; */
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
  font-weight: 400;
}

#table td {
  text-align: left;
  vertical-align: middle;
}
#table td input {
  width: 100%;
  box-sizing: border-box; /* include padding and border in width */
}
.sort-icon {
  margin-left: 10px;
}
.input-container input {
  font-size: 16px;
}
</style>
